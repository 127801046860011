<template>
  <v-card
    class="pa-6 d-flex"
    style="
      flex-direction: column;
      gap: 28px;
      background: var(--v-background_light-base);
    "
    rounded="xl"
  >
    <div class="title-1 font-weight-bold onSurface_dark--text">
      {{ $t("mypage.editProfile") }}
    </div>
    <div
      class="d-flex"
      :class="$vuetify.breakpoint.mdAndDown ? 'px-0' : 'px-6'"
      style="gap: 60px"
      :style="
        $vuetify.breakpoint.smAndDown && 'flex-direction: column; gap: 20px'
      "
    >
      <v-card
        flat
        class="d-flex transparent"
        style="flex-direction: column; gap: 24px; flex: 1"
      >
        <v-avatar size="160" class="d-flex mx-auto">
          <v-img :src="newUserData.photoURL" />
        </v-avatar>
        <div class="subtitle-1 option-area">
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.displayName") }}
            </div>
            <v-spacer />
            <v-text-field
              v-model="newUserData.displayName"
              outlined
              dense
              hide-details
              :style="option_input"
            />
          </div>
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.nickName") }}
            </div>
            <v-spacer />
            <v-text-field
              v-model="newUserData.nickName"
              outlined
              dense
              hide-details
              :style="option_input"
            />
          </div>
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.email") }}
            </div>
            <v-spacer />
            <v-text-field
              disabled
              v-model="newUserData.email"
              :rules="[rules.email]"
              outlined
              dense
              hide-details
              :style="option_input"
            />
          </div>
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.country") }}
            </div>
            <v-spacer />
            <v-select
              v-model="newUserData.countryCode"
              :items="countries"
              item-text="code"
              item-value="code"
              class="select"
              attach=".select"
              hide-details="auto"
              outlined
              dense
              solo
              flat
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              :menu-props="{
                maxHeight: 250,
                offsetY: true,
                contentClass: 'elevation-1',
              }"
              @change="onSelectChange"
              :style="option_input"
            >
              <template slot="append">
                <v-icon size="16" color="onSurface_light">
                  mdi-chevron-down
                </v-icon>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex flex-column" style="gap: 6px">
                  <div class="d-flex" style="gap: 6px">
                    <v-img max-width="22" :src="flagFileName(item)" />
                    <span
                      class="text-capitalize subtitle-2 onSurface_dark--text"
                    >
                      {{ $i18n.locale == "ko" ? item.name_kor : item.name }}
                      ({{ item.code }})
                    </span>
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div
                  class="d-flex align-center"
                  style="gap: 6px; max-width: 100%"
                >
                  <v-img
                    max-width="22"
                    max-height="16"
                    :src="flagFileName(item)"
                  />
                  <span class="text-capitalize subtitle-2 onSurface_dark--text">
                    {{
                      $i18n.locale == "ko"
                        ? `${item.name_kor} (${item.code}) (${item.name})`
                        : `${item.name} (${item.code})`
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-select>
          </div>
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.phoneNumber") }}
            </div>
            <v-spacer />
            <v-text-field
              v-model="newUserData.phoneNumber"
              outlined
              dense
              hide-details
              :style="option_input"
            />
          </div>
          <div
            class="d-flex pa-3"
            :style="option_box"
            v-if="newUserData.phoneNumber != userData.phoneNumber && !verified"
          >
            <div :class="option_key">
              {{ $t("mypage.userData.verify") }}
            </div>
            <v-spacer />
            <v-text-field
              v-model="verifyCode"
              outlined
              dense
              hide-details
              :style="option_input"
              @input="verifyInput($event)"
            >
              <template v-slot:append-outer>
                <v-btn
                  color="primary"
                  @click="requestVerify()"
                  :loading="verifyLoading"
                  height="40px"
                  class="px-2"
                >
                  {{ $t("signup.verifyBtn") }}
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <div class="d-flex pa-3" :style="option_box">
            <div :class="option_key">
              {{ $t("mypage.userData.marketing") }}
            </div>
            <v-spacer />
            <div style="height: 40px" class="d-flex" :style="option_input">
              <v-icon
                :class="option_key"
                @click="
                  newUserData.agree.marketing = !newUserData.agree.marketing
                "
              >
                {{
                  newUserData.agree.marketing
                    ? "mdi-checkbox-marked"
                    : "mdi-checkbox-blank-outline"
                }}
              </v-icon>
            </div>
          </div>
        </div>
      </v-card>
      <div
        class="d-flex"
        :style="
          $vuetify.breakpoint.lgAndUp
            ? 'flex: 2; gap: 60px'
            : 'flex: 1; gap: 40px; flex-direction: column'
        "
      >
        <v-card
          flat
          class="d-flex transparent"
          style="flex-direction: column; gap: 20px; flex: 1"
        >
          <div class="title-2 font-weight-bold">
            {{ $t("mypage.makerInfo") }}
          </div>
          <div class="option-area">
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.maker.makerSpace") }}
              </div>
              <v-spacer />
              <v-autocomplete
                v-model="newUserData.makerInfo.makerSpace"
                :style="option_input"
                :items="schoolList"
                cache-items
                single-line
                hide-no-data
                hide-details
                dense
                outlined
              />
            </div>
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.maker.makerId") }}
              </div>
              <v-spacer />
              <v-text-field
                v-model="newUserData.makerInfo.makerId"
                dense
                outlined
                hide-details
                :style="option_input"
              />
            </div>
          </div>
        </v-card>
        <v-card
          flat
          class="d-flex transparent"
          style="flex-direction: column; gap: 20px; flex: 1"
        >
          <div class="title-2 font-weight-bold">
            {{ $t("mypage.companyInfo") }}
          </div>
          <div class="option-area">
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.company.companyName") }}
              </div>
              <v-spacer />
              <v-text-field
                v-model="newUserData.companyInfo.companyName"
                dense
                outlined
                hide-details
                :style="option_input"
              />
            </div>
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.company.cheifName") }}
              </div>
              <v-spacer />
              <v-text-field
                v-model="newUserData.companyInfo.cheifName"
                dense
                outlined
                hide-details
                :style="option_input"
              />
            </div>
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.company.email") }}
              </div>
              <v-spacer />
              <v-text-field
                v-model="newUserData.companyInfo.email"
                :rules="[rules.email]"
                dense
                outlined
                hide-details
                :style="option_input"
              />
            </div>
            <div class="d-flex subtitle-1 pa-3" :style="option_box">
              <div :class="option_key">
                {{ $t("mypage.company.companyId") }}
              </div>
              <v-spacer />
              <v-text-field
                v-model="newUserData.companyInfo.companyId"
                dense
                outlined
                hide-details
                :style="option_input"
              />
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <v-btn
      block
      class="title-1 font-weight-bold"
      color="primary"
      height="52px"
      style="border-radius: 12px"
      @click="saveData()"
    >
      {{ $t("save") }}
    </v-btn>
    <div class="d-flex subtitle-1">
      <div class="onSurface_normal--text" @click="openChat()">
        {{ $t("mypage.need_help") }}
      </div>
      <v-spacer />
      <div class="error--text" @click="dialogDelete = true">
        {{ $t("mypage.delete_account") }}
      </div>

      <v-dialog v-model="dialogDelete" max-width="280px">
        <v-card class="rounded-xl text-center pa-3" flat>
          <v-card-title class="title font-weight-bold justify-center">
            {{ $t("mypage.delete_account_title") }}
          </v-card-title>
          <v-card-subtitle class="ma-0">
            {{ $t("mypage.delete_account_text") }}
          </v-card-subtitle>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              class="rounded-lg flex-grow-1"
              depressed
              @click="dialogDelete = false"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="error"
              class="rounded-lg flex-grow-1"
              depressed
              @click="deleteItemConfirm"
              :loading="deleteBtnLoading"
            >
              {{ $t("delete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<style scoped>
.option-area {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
::v-deep .v-input__append-outer {
  margin: 0px 0px 0px 8px !important;
}
</style>

<script>
import _ from "lodash";
import API from "@/API/auth.js";
import rules from "@/utils/rules.js";
import constants from "@/utils/constants";
import countries from "@/assets/flags/flags.js";

export default {
  name: "MyPage-Edit",
  props: { userData: Object },
  data() {
    return {
      newUserData: constants.userInterface,

      countries: countries,

      verifyLoading: false,
      verified: false,
      verifyCode: "",
      verifyCodeAnswer: "",

      dialogDelete: false,
      deleteBtnLoading: false,
      rules: rules,

      schoolList: constants.schoolList,
    };
  },
  computed: {
    option_box() {
      if (this.$vuetify.breakpoint.xs) return "flex-direction: column";
      if (this.$vuetify.breakpoint.sm) return "flex-direction: column";
      if (this.$vuetify.breakpoint.md) return "flex-direction: column";
      if (this.$vuetify.breakpoint.lg) return "min-width: 340px";
      return "";
    },
    option_key() {
      if (this.$vuetify.breakpoint.xs) return "mb-1";
      if (this.$vuetify.breakpoint.sm) return "mb-1";
      if (this.$vuetify.breakpoint.md) return "mb-1";
      if (this.$vuetify.breakpoint.xs) return "mb-1";
      return "my-auto";
    },
    option_input() {
      if (this.$vuetify.breakpoint.xs) return "width: 100%";
      if (this.$vuetify.breakpoint.sm) return "width: 100%";
      if (this.$vuetify.breakpoint.md) return "width: 100%";
      if (this.$vuetify.breakpoint.lgAndUp) return "max-width: 210px";
      return "";
    },
  },
  beforeMount() {
    this.newUserData = _.cloneDeep(this.userData);
  },
  watch: {
    userData: {
      handler() {
        if (!this.newUserData.uid) {
          this.newUserData = _.cloneDeep(this.userData);
        }
      },
    },
  },
  methods: {
    /**
     * 국가 선택을 바탕으로 자동으로 페이지 언어를 변환합니다.
     */
    onSelectChange(select) {
      this.$i18n.locale = select.includes("+82") ? "ko" : "en";
    },
    /**
     * 국가 플래그 이미지 파일명을 생성합니다.
     * @param {Object} item 국가 정보 객체
     * @returns {String} 국가 플래그 이미지의 경로
     */
    flagFileName(item) {
      let filename = item.flag || item.name;
      filename = filename.replaceAll(" ", "-");
      return require("@/assets/flags/" + filename + ".svg");
    },
    async requestVerify() {
      if (this.newUserData.phoneNumber.match(/^\d{2,3}-\d{3,4}-\d{4}$/)) {
        this.newUserData.phoneNumber = this.newUserData.phoneNumber.replaceAll(
          /-/g,
          ""
        );
      }
      if (!this.newUserData.phoneNumber.match(/^\d{11}$/)) {
        return this.$toasted.global.error(
          "연락처를 정확히 입력해주세요. (예시. 01012345678)"
        );
      }
      this.verifyLoading = true;

      const phone = this.newUserData.phoneNumber;
      const authPhoneNumber =
        this.newUserData.countryCode +
        (phone.startsWith("0") ? phone.slice(1) : phone);

      //전화번호 중복을 대비해 추가 검증
      const res = await this.$axios.get("auth/check-user-existence", {
        params: { phone: authPhoneNumber },
      });

      if (res.status != 204) {
        if (res.status != 200) {
          this.$toasted.global.error(
            this.$i18n.t("auth.unknown_error_occured")
          );
        }

        const uid = res.data.uid;
        if (uid.includes("kakao")) {
          this.$toasted.global.error(this.$i18n.t("auth.kakao_account_exist"));
        } else if (uid.includes("naver")) {
          this.$toasted.global.error(this.$i18n.t("auth.naver_account_exist"));
        } else if (
          res.data.providerData &&
          res.data.providerData.length > 0 &&
          res.data.providerData[0].providerId == "google.com"
        ) {
          this.$toasted.global.error(this.$i18n.t("auth.google_account_exist"));
        } else {
          this.$toasted.global.error(this.$i18n.t("auth.phone_account_exist"));
        }
        return;
      }

      this.$axios
        .patch("/notice/RequestVerify", {
          phoneNumber: authPhoneNumber,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$toasted.global.error(this.$t("signup.serverError"));
          }

          this.verifyCodeAnswer = res.data.code;

          // 로컬 테스트용 인증번호
          // console.log(this.verifyCodeAnswer);

          this.verifyLoading = false;
          this.$toasted.global.notice(this.$t("signup.verifySended"));
        })
        .catch(() => {
          this.$toasted.global.error(this.$t("signup.serverLost"));
          this.verifyLoading = false;
        });
    },

    verifyInput() {
      if (this.verifyCode.length == 6) {
        if (this.verifyCode == this.verifyCodeAnswer) {
          this.verified = true;
          this.$toasted.global.notice(this.$t("signup.verified"));
        } else {
          this.$toasted.global.error(this.$t("signup.notVerified"));
        }
      }
    },
    // 회원탈퇴
    async deleteItemConfirm() {
      this.deleteBtnLoading = true;
      await this.$firebase
        .auth()
        .currentUser.delete()
        .catch((e) => {
          console.log(e);
          this.$toasted.global.error(
            "인증이 만료되었습니다. 탈퇴를 위하여 다시 로그인 해 주세요"
          );
          this.$router.push("/login");
        })
        .then(() => {
          this.$store.dispatch("auth/user/SET_LOGOUT", "Home").then(() => {
            API.rebootChannelIo();
          });
        });
    },
    openChat() {
      window.ChannelIO("openChat", undefined, "hi");
    },
    saveData() {
      if (
        this.newUserData.companyInfo.email &&
        this.rules.email(this.newUserData.companyInfo.email) != true
      )
        return this.$toasted.global.error("기업 이메일이 유효하지 않습니다!");
      if (
        this.newUserData.countryCode != this.userData.countryCode &&
        this.userData.phoneNumber == this.newUserData.phoneNumber
      )
        this.newUserData.countryCode = this.userData.countryCode;
      if (
        this.userData.phoneNumber != this.newUserData.phoneNumber &&
        !this.verified
      )
        return this.$toasted.global.error(
          "변경된 연락처로 인증을 진행해주세요"
        );

      this.$emit("saveData", this.newUserData);
    },
  },
};
</script>
